/*header style*/

html {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  // font-family: 'Droid Arabic Kufi', sans-serif;
}
.headerDetails {
  text-shadow: 0 0 2px #ffffff;
}

.fixMargin {
  margin-top: 90px;
}
.p-dialog .p-dialog-titlebar .p-dialog-title {
  float: right !important;
}
.p-dialog .p-dialog-titlebar-icons {
  float: none !important;
}
.revBanner {
  position: absolute;
  top: 30%;
  left: 30%;
  transform: translate(-30%, -30%);
  width: 80%;
  height: 20%;
  z-index: 99;
  color: #ffffff;

  @media (max-width: 1200px) {
    top: 30%;
    transform: translate(-30%, -30%);
  }
  @media (max-width: 992px) {
    top: 1%;
    transform: translate(-30%, -1%);
    visibility: hidden;
  }
  @media (max-width: 768px) {
    top: 0%;
    transform: translate(-30%, -0%);
    visibility: hidden;
  }
  @media (max-width: 576px) {
    top: 1%;
    transform: translate(-30%, 1%);
    visibility: hidden;
  }
}
.minRevBanner {
  position: absolute;
  top: 5%;
  left: 10%;
  transform: translate(-5%, -10%);
  width: 80%;
  height: 20%;
  z-index: 99;
  color: #ffffff;
  visibility: hidden;
}
.revBanner-header {
  animation-name: re-anim;
  animation-duration: 4s;
  //animation-delay: 4s;
  //animation-iteration-count: infinite;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
  color: #fcb815 !important;
  @media (max-width: 1200px) {
    //color: #17a2b8 !important;
    font-size: 3rem !important;
    font-weight: 300 !important;
    line-height: 1.2 !important;
  }
  @media (max-width: 992px) {
    //color: #17a2b8 !important;
    font-size: 2.5rem !important;
    font-weight: 300 !important;
    line-height: 1.2 !important;
  }
  @media (max-width: 768px) {
    //color: #17a2b8 !important;
    font-size: 2rem !important;
    font-weight: 300 !important;
    line-height: 1.2 !important;
  }
  @media (max-width: 576px) {
    //color: #17a2b8 !important;
    font-size: 1.5rem !important;
    font-weight: 300 !important;
    line-height: 1.2 !important;
  }
}
.revBanner-paragraph {
  animation-name: re-anim;
  animation-duration: 4s;
  animation-delay: 2s;
  animation-fill-mode: backwards;
  //animation-iteration-count: infinite;
}
@keyframes re-anim {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
#main_container_outer {
  position: relative;
}
.main_container {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  min-height: 450px;
  // left: 50%;
  // transform: translateX(-50%);
  // -webkit-box-shadow: 1px -3px 2px 0px #FCB815, -1px -3px 2px 0px #FCB815;
  -webkit-box-shadow: 0 0px 3px 0 rgba(252, 184, 20, 0.5),
    0 -1px 2px 0 rgba(252, 184, 20, 0.5);
  -moz-box-shadow: 0 0px 3px 0 rgba(252, 184, 20, 0.5),
    0 -1px 2px 0 rgba(252, 184, 20, 0.5);
  box-shadow: 0 0px 3px 0 rgba(252, 184, 20, 0.5),
    0 -1px 2px 0 rgba(252, 184, 20, 0.5);
  margin: 0px;
  border-radius: 2px;
}
// .main_container2 {
//   position: absolute;
//   top: -130px;
//   left: 0;
//   right: 0;
//   margin-left: auto;
//   margin-right: auto;
//   min-height: 450px;
//   // left: 50%;
//   // transform: translateX(-50%);
//   // -webkit-box-shadow: 1px -3px 2px 0px #FCB815, -1px -3px 2px 0px #FCB815;
//   -webkit-box-shadow: 0 0px 3px 0 rgba(252, 184, 20, 0.5),
//     0 -1px 2px 0 rgba(252, 184, 20, 0.5);
//   -moz-box-shadow: 0 0px 3px 0 rgba(252, 184, 20, 0.5),
//     0 -1px 2px 0 rgba(252, 184, 20, 0.5);
//   box-shadow: 0 0px 3px 0 rgba(252, 184, 20, 0.5),
//     0 -1px 2px 0 rgba(252, 184, 20, 0.5);
//   margin: 0px;
//   border-radius: 2px;
// }
.main_container_inner {
  border-radius: 2px;
}
/* Navigator style here*/
.logo {
  width: auto;
  height: 40px;
}
.logo2 {
  width: auto;
  height: auto;
  // margin-bottom: 5px;
  margin-top: 0px;
  // visibility: visible;
  // display: block;
  @media (max-width: 767px) {
    margin-top: -60px;
    //   visibility: hidden;
    //   display: none;
  }
}
.menu-logo {
  margin-top: 10px;
  width: auto;
  height: 40px;
}
.mainNavBar.navbar {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  //border-bottom: 2px solid #FCB815;
  border-style: inset;
}
.navbar-light .navbar-toggler {
  border-color: #fcb815 !important;
  visibility: hidden !important;
}
.hNavBar .navbar-nav .nav-link:hover {
  color: #fcb815 !important;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(252, 184, 20, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-custom {
  color: #000000;
  background-color: #cc3333;
}
.navbar-nav .nav-item .nav-link {
  color: #000000;
}
.hNavBar {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 201;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  @media (min-width: 768px) {
    // transition: 0.6s;
    background: -moz-linear-gradient(
      left,
      rgba(238, 238, 238, 0.4) 0%,
      rgba(204, 204, 204, 0.4) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      rgba(238, 238, 238, 0.4) 0%,
      rgba(204, 204, 204, 0.4) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      rgba(238, 238, 238, 0.4) 0%,
      rgba(204, 204, 204, 0.4) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66eeeeee', endColorstr='#66cccccc',GradientType=1 ); /* IE6-9 */
  }
}
.container {
  // @media (min-width: 576px) and (max-width: 768px) {
  //   max-width: 800px !important;
  // }

  @media (min-width: 768px) {
    max-width: 760px !important;
  }
  @media (min-width: 992px) {
    max-width: 960px !important;
  }
  @media (min-width: 1200px) {
    max-width: 1140px !important;
  }
}
.ml-auto .navbar-nav {
}
.navbar-collapse {
  width: 200px !important;
  background: transparent;
  text-align-last: right;
  @media (max-width: 767px) {
    background: -moz-linear-gradient(
      left,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 1%,
      rgba(0, 0, 0, 0.6) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 1%,
      rgba(0, 0, 0, 0.6) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 1%,
      rgba(0, 0, 0, 0.6) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99000000', endColorstr='#99000000',GradientType=1 ); /* IE6-9 */

    // background: -moz-linear-gradient(
    //   left,
    //   rgba(238, 238, 238, 0.4) 0%,
    //   rgba(204, 204, 204, 0.4) 100%
    // ); /* FF3.6-15 */
    // background: -webkit-linear-gradient(
    //   left,
    //   rgba(238, 238, 238, 0.4) 0%,
    //   rgba(204, 204, 204, 0.4) 100%
    // ); /* Chrome10-25,Safari5.1-6 */
    // background: linear-gradient(
    //   to right,
    //   rgba(238, 238, 238, 0.4) 0%,
    //   rgba(204, 204, 204, 0.4) 100%
    // ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66eeeeee', endColorstr='#66cccccc',GradientType=1 ); /* IE6-9 */
  }
}
.white_with_shadow {
  color: #fff !important;
  font-size: 1.3rem !important;
  //font-weight: 400 !important;
  //padding: 0px 5px;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9) !important;
}
.grey_with_shadow {
  color: chocolate !important;
  //color: #3d3a3a !important;
  //font-weight: 400 !important;
  //padding: 0px 5px;
  text-shadow: 0.001px 0.001px 1.8px #636262 !important;
}
.black_with_shadow {
  color: rgb(27, 25, 23) !important;
  //color: #3d3a3a !important;
  //font-weight: 400 !important;
  //padding: 0px 5px;
  text-shadow: 0.001px 0.001px 1.8px #636262 !important;
}
.nav-link-log {
  border: 1px solid #f18805;
  border-radius: 5px;
  transition: all 0.5s !important;
  padding: 5px 0px;
}
.logpos {
  margin-right: 10px;
}
.nav-link-log:hover {
  border: 1px solid #fcb815;
}
// here2
.navbar {
  @media (max-width: 767px) {
    //@media (max-width: 576px) {
    position: relative;
    width: 250px !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1.2rem;
    margin-right: 0 !important;
  }
}
.navbar-nav .nav-item .nav-link {
  color: rgb(241, 228, 151) !important;
  font-size: 1rem !important;
  //font-weight: 400 !important;
  //padding: 0px 5px;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9) !important;
}
.hNavBar .dropdown-item {
  //color: rgb(241, 228, 151) !important;
  //font-size: 1.3rem !important;
  //font-weight: 400 !important;
  //padding: 0px 5px;
  text-shadow: 2px 2px 1px rgba(255, 255, 255, 1) !important;
}
.hNavBar .dropdown-menu {
  //background: transparent !important;
  background: rgb(252, 184, 20); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(252, 184, 20, 1) 0%,
    rgba(252, 184, 20, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(252, 184, 20, 1) 0%,
    rgba(252, 184, 20, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(252, 184, 20, 1) 0%,
    rgba(252, 184, 20, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FCB815', endColorstr='#FCB815',GradientType=1 ); /* IE6-9 */
}
.label_white_with_shadow {
  color: #fff !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  //padding: 0px 5px;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
}
.label_black_with_shadow {
  color: #000 !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  //padding: 0px 5px;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.9);
}
.miniNavigator {
  position: fixed;
  top: 5px;
  z-index: 201;
  width: 70px !important;
  left: 80% !important;
  transition: 0.6s;
  background: transparent;
  height: 40px;
  padding: 0px !important;
  margin: 0px !important;
  @media (max-width: 1200px) {
    left: 83% !important;
  }
  @media (max-width: 992px) {
    left: 82% !important;
  }
  @media (max-width: 768px) {
    left: 82% !important;
  }
  @media (max-width: 575px) {
    left: 75% !important;
  }
  @media (max-width: 320px) {
    left: 60% !important;
  }
  // background: -moz-linear-gradient(
  //   left,
  //   rgba(51, 51, 51, 0.3) 0%,
  //   rgba(51, 51, 51, 0.3) 100%
  // );  FF3.6-15
  // background: -webkit-linear-gradient(
  //   left,
  //   rgba(51, 51, 51, 0.3) 0%,
  //   rgba(51, 51, 51, 0.3) 100%
  // );  Chrome10-25,Safari5.1-6
  // background: linear-gradient(
  //   to right,
  //   rgba(51, 51, 51, 0.3) 0%,
  //   rgba(51, 51, 51, 0.3) 100%
  // );  W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d333333', endColorstr='#4d333333',GradientType=1 );  IE6-9
  .menu-btn {
    visibility: visible;
  }
  .mainNavBar {
    visibility: hidden;
  }
}

.menu-btn {
  position: absolute;

  visibility: hidden;
  padding: 5px 10px;
  transition: all 1.2s;
  transition: none !important;
  //background-color: #ecd497;
  //background-color: transparent !important;
  border: none !important;
}
.menu-btn:hover {
  transition: none !important;
  //background-color: transparent !important;
  border: none !important;
}
.menu-btn:focus {
  outline: 0;
}
.fixedNavigator {
  position: fixed;
  z-index: 999;
  top: 0;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  background: rgb(255, 255, 255); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgba(195, 226, 243, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgba(195, 226, 243, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgba(195, 226, 243, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#c3e2f3',GradientType=0 ); /* IE6-9 */

  transition: all 0.6s;
}
.invalid-feedback-custom {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.react-tel-input .form-control {
  width: 100% !important;
}
.react-tel-input .form-control1 {
  width: 50% !important;
}
.navContact {
  border-radius: 50%;
}
.mainNavBar {
  //transition: 0.6s;
}
.hNavBar .navbar-nav .nav-link {
  font-size: 13px !important;
  letter-spacing: 0.5px !important;
  transition: color 0.5s !important;
  text-transform: uppercase;
  font-weight: normal;
  @media (min-width: 1200px) {
    font-weight: bold;
    font-size: 14px !important;
    letter-spacing: 1px !important;
  }
}

custom-navbar {
  padding-top: 5px !important;
  padding-bottom: 5px !important;

  background: rgb(255, 255, 255); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgba(195, 226, 243, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgba(195, 226, 243, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgba(195, 226, 243, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#c3e2f3',GradientType=0 ); /* IE6-9 */

  transition: all 0.6s;
}
.no_select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.no_select_img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
.main_carousel_image {
  -webkit-filter: grayscale(40%); /* Safari 6.0 - 9.0 */
  filter: grayscale(40%);
}
// Deals style
.deals-carousel img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
}
.deals-carousel .control-dots {
  bottom: -15px;
}
.deals-carousel .carousel.carousel-slider {
  padding-bottom: 20px;
}
.deals-carousel .legend:hover {
  background-color: #cc3333;
  opacity: 0.2;
}
.deals-carousel .deal-container {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 90%;
  height: 30%;
  background-color: #000000;
  opacity: 0.45;
  border-radius: 10px;
  //transition: all 0.3s;
}
.deals-carousel .deal-details {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 90%;
  height: 30%;
  color: #ffffff;
  opacity: 1;
  letter-spacing: 0.5px;
  font-weight: bold;
  //transition: all 0.3s;
}
.deals-carousel .deal-container:hover {
  //transform: translateY(-3px);
}
.deals-carousel .deal-details:hover {
  //transform: translateY(-3px);
}
.owl-item .item {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.carousel.carousel-slider li {
  background-color: #ffffff;
}
//partners style
.partners-carousel img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  width: 100px;
  @media (min-width: 768px) {
    padding: 35px 50px;
  }
}
.partners-carousel .control-dots {
  bottom: -15px;
}
.partners-carousel .carousel.carousel-slider {
  padding-bottom: 20px;
}
.logo_color {
  color: #fcb614;
}

//Footer
footer .footer_link {
  color: #ffffff;
  font-size: 1rem;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
  transition: all 0.5s;
}

footer .footer_link:hover {
  color: #fcb815;
  text-decoration: none;
}
.main-footer {
  background-color: #414042;
}
//addProperty style
.show-submitted-property {
  //direction: rtl;
  //display: flex;
  //height: 342px;
  //overflow: auto !important;
  overflow-y: scroll;
  //background-color: #f8f9fa;
}
.fileupload-images {
  background-color: #f4f4f4;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: #333333;
  border-bottom: 0 none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
// .fileupload_images_content {
//   // position: relative;
//   // background-color: #ffffff;
//   // padding: 0.571em 1em;
//   // border: 1px solid #c8c8c8;
//   // color: #333333;
//   // border-bottom-left-radius: 3px;
//   // border-bottom-right-radius: 3px;
//   .clearfix:after {
//     content: '';
//     display: table;
//     clear: both;
//   }
//   .draggable {
//     margin-right: 5px;
//     margin-bottom: 5px;
//     //float: left;
//     width: 23%;
//     height: 164px;
//     background-color: #ffffff;
//     border: 1px solid #c8c8c8;
//     border-radius: 10px;

//     &.dragged {
//       border: 1px solid red;
//     }

//     &.placeholder {
//       opacity: 0.5;
//     }
//     @media (max-width: 1200px) {
//       width: 31%;
//     }
//   }
// }
.fileupload_images_content_center .List {
  margin: auto;
}
.propertyFileUpload .p-button.p-component.p-button-text-icon-left:nth-child(2) {
  display: none;
}
.propertyFileUpload .p-fileupload-content {
  // display: none;
}

.propertyFileUpload .p-button.p-component.p-button-text-icon-left:nth-child(3) {
  background-color: #cc3333;
}
.propertyFileUpload .p-button.p-component.p-button-icon-only {
  //display: none;
}
body .p-calendar.p-calendar-w-btn .p-inputtext {
  width: 120px !important;
}
//login style
.password-label {
  display: block;
  position: relative;
}
.search-category {
  color: gray;
}
.search-category-link:hover {
  color: #fcb815;
}
.password-hide-show-e {
  cursor: pointer;
  position: absolute;
  bottom: 34px;
  height: 28px;
  width: 40px;
  // right: 0;
  left: 0px;
  background: transparent;
  color: white;
  padding: 1px 8px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 0.8em;
}
.password-hide-show {
  cursor: pointer;
  position: absolute;
  bottom: 11px;
  height: 28px;
  width: 40px;
  // right: 0;
  left: 0px;
  background: transparent;
  color: white;
  padding: 1px 8px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 0.8em;
}
.login-div input.form-control {
  background: transparent;
  border-color: transparent;
  border-bottom: 2px solid #ccc;
  border-radius: 0;
  transition: all 0.8s;
}
.login-div input.form-control:focus {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
  color: #000000;
  border-bottom: 2px solid #f5593d;
}

.submit-button {
  background: #fcb815; //#f18805;
  transition: all 0.4s;
  border-radius: 2px !important;
}
.submit-button:hover {
  background: #f18805;
  transform: translateY(-2px);
}

.shining {
  box-shadow: 0px 0px 5px 1px #fcb815 !important;
  opacity: 0.7;
}
.submit-search-button {
  background: #febb1a; //#f18805;
  transition: all 0.4s;
  border-radius: 2px !important;
  width: 80px !important;
}
.submit-search-button:hover {
  background: #f18805;
  transform: translateY(-2px);
}
.header-submit-search-button-min {
  background: #fcb815; //#f18805;
  transition: all 0.4s;
  border-radius: 2px !important;
  width: 42px !important;
}
.header-submit-search-button-min:hover {
  background: #f18805;
  transform: translateY(-2px);
}
.submit-search-button-min {
  background: #fcb815; //#f18805;
  transition: all 0.4s;
  border-radius: 2px !important;
  width: 80px !important;
}
.submit-search-button-min:hover {
  background: #f18805;
  transform: translateY(-2px);
}

.p-selectbutton .p-button {
  background-color: #fcb815 !important;
}
.p-selectbutton .p-button.p-highlight {
  background-color: #f18805 !important;
  border-color: #581f18 !important;
}
.p-selectbutton .p-button.p-highlight:not(.p-disabled):hover {
  background-color: #f18805 !important;
  border-color: #581f18 !important;
}
.p-selectbutton .p-button.p-focus,
.p-selectbutton .p-button:focus {
  box-shadow: 0 0 0 0.06em #581f18 !important;
}
//property style
.div_map_marked {
  position: absolute;
  top: 40px;
  left: 0%;
  width: 10%;
  transform: translate(-0%, 0);
  z-index: 100;
}
.btn-map-marked {
  transition: all 1.2s;
  transition: none !important;
  background-color: transparent !important;
  border: none;
}
.icon-map-marked {
  transition: all 0.8s;
}
.icon-map-marked:hover {
  //color: #FCB815;
  color: #f18805;
}
.btn-map-marked:hover {
  transition: none !important;
  background-color: transparent !important;
  border: none !important;
}
.btn-map-marked:focus {
  outline: 0;
}
.btn-search-active {
  border-bottom: 2px solid #fff;
  background: #007ad9;
}
.card-body {
  overflow: hidden !important;
}
.card-img-content {
}

.deal-container {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -80%);
  width: 80%;
  height: 40%;
  background-color: #000000;
  opacity: 0.45;
  border-radius: 10px;
  z-index: 100;
  //transition: all 0.3s;
}
.deal-details {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -70%);
  width: 90%;
  height: 20%;
  color: #ffffff;
  opacity: 1;
  letter-spacing: 0.5px;
  font-weight: bold;
  z-index: 101;
  //transition: all 0.3s;
}
.property_container_div {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -70%);
  width: 98%;
  height: 17%;
  background-color: #000000;
  opacity: 0.45;
  border-radius: 10px;
  z-index: 100;
  //transition: all 0.3s;
}

.property-header-top-right {
  position: absolute;
  top: 1%;
  left: 90%;
  transform: translate(-90%, -1%);
  width: 98%;
  height: 17%;
  font-weight: bold;
  z-index: 102;
  //transition: all 0.3s;
}
.property-header {
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translate(-50%, -1%);
  width: 98%;
  height: 17%;
  font-weight: bold;
  z-index: 101;
  //transition: all 0.3s;
}
.property-header2 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
  width: 98%;
  height: 8%;
  font-weight: bold;
  z-index: 101;
  //transition: all 0.3s;
}

.property-lakta {
  color: #fcb815 !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 5px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
}
.property-position {
  color: #fff !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 5px;
  text-shadow: 1.5px 1.5px 1px rgba(0, 0, 0, 0.9);
  // text-shadow: 1px 1px 1px #FCB815;
}
.property-title {
  color: #fff !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 5px;
  text-shadow: 1.5px 1.5px 1px rgba(0, 0, 0, 0.9);
}
.property-title-2x {
  color: #581f18 !important;
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px;
  // padding: 0px 5px;
  text-shadow: 1.5px 1.5px 1px #fff;
  @media (max-width: 576px) {
    font-size: 1rem !important;
  }
}
.property-title-3x {
  color: black !important;
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // padding: 0px 5px;
  text-shadow: 1.5px 1.5px 1px #fff;
  @media (max-width: 576px) {
    font-size: 1rem !important;
  }
}
.second-color {
  color: #581f18;
}
.property-title-lg {
  color: #fff !important;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  line-height: 1 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // padding: 0px 5px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9);
}
.property-footer {
  color: #000 !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 5px;
  // text-shadow: 0px 0px 1px #000;
  overflow: hidden;
}
.property-footer-offer {
  background-color: #febb1a;
  color: #000 !important;
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  // text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.9);
  height: 100%;
  max-height: -webkit-fill-available;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  // display: flex;
  // word-wrap: break-word;
  // align-items: center;
  // justify-content: center;
  -webkit-box-shadow: 1px 0px 2px 0px #000;
  -moz-box-shadow: 1px 0px 2px 0px #000;
  box-shadow: 1px 0px 2px 0px#000;
}
.property-details {
  color: #fcb815;
  //color: #f18805;
  letter-spacing: 0.5px;
  //font-weight: bold;
}
.property-main-image {
  width: 100%;
  // height: 100%;
  //width: 243px;
  height: 246.4px;
  padding: 0px;
  margin: 0px;
  display: block;
  -o-object-fit: fill;
  object-fit: fill;
  @media (min-width: 575px) {
    -webkit-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s;
  }
}
.property-main-image:hover {
  transform: none;
  @media (min-width: 575px) {
    transform: scale(1.1);
  }
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: #008cba;
  overflow: hidden;
  width: 0%;
  height: 100%;
  //visibility: hidden;
  //transition: all 0.5s ease;
}

// .image-container:hover .overlay {
//   //visibility: visible;
//   animation: slide 0.6s 1 linear forwards;
// }
@keyframes slide {
  0% {
    visibility: visible;
    width: 10%;
    left: 0;
  }
  50% {
    width: 50%;
    left: 0;
  }
  100% {
    width: 100%;
    left: 0;
    visibility: hidden;
  }
}

.icon-arrow {
  transition: all 0.8s;
}
.icon-arrow:hover {
  //color: #FCB815;
  color: #f18805;
}
.btn-arrow {
  transition: all 1.2s;
  transition: none !important;
  background-color: transparent !important;
  border: none !important;
}
.btn-arrow:hover {
  transition: none !important;
  background-color: transparent !important;
  border: none !important;
}
.btn-arrow:focus {
  outline: 0;
}
.next-arrow {
  position: absolute;
  left: 2%;
  top: 40%;
  transform: translate(-2%, -40%);
  z-index: 100;
  cursor: pointer;
  transition: all 0.6s;
}
.previous-arrow {
  position: absolute;
  left: 96%;
  top: 40%;
  transform: translate(-96%, -40%);
  z-index: 100;
  cursor: pointer;
  transition: all 0.6s;
}

.card-footer {
}
.property-container {
  position: relative;
  direction: rtl;
}
.property_card {
  position: relative;
  box-shadow: 2px 2px 5px #aaa;
  max-width: 420px;
  height: 300px;
  overflow: hidden;
  transition: all 0.8s;
}

.rotate {
  perspective: 100rem;
}
.backside {
  position: absolute;
  top: -100%;
  background-color: #fefefe;
  height: 100%;
  width: 100%;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  transform: rotateY(180deg);
  transition: top 0.8s, opacity 0.4s;
}

// .backside-content {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 90%;
//   height: 90%;
//   transform: translate(-50%, -50%);
// }
// .backside-footer {
//   position: absolute;
//   top: 220px;
//   left: 50%;
//   width: 90%;
//   transform: translate(-50%, 0);
//   // bottom: 0px;
// }
// .backside-details {
//   position: absolute;
//   //top: 225px;
//   left: 50%;
//   width: 110%;
//   transform: translate(-50%, 0);
//   // bottom: 0px;
// }
// .rotate:hover .property-card {
//   transform: rotateY(180deg);
// }

// .rotate:hover .backside {
//   opacity: 1;
//   top: 0;
//   user-select: auto;
//   pointer-events: auto;
// }

/*LOADER*/
.loader {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1100;
  overflow: hidden;
  background: #70aed2;
}
.loader .span {
  display: table;
  height: 44px;
  left: 50%;
  position: absolute;
  width: 40px;
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.process-loader {
  position: fixed;
  top: 0%;
  left: 0%;
  //transform: translate(-10%, -10%);
  width: 100%;
  height: 100%;
  z-index: 190;
  overflow: hidden;
  background: #70aed2;
  opacity: 0.5;
}
.process-loader .span {
  display: table;
  height: 44px;
  left: 50%;
  position: absolute;
  width: 40px;
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.process-loader2 {
  position: absolute;
  top: 0%;
  left: 0%;
  //transform: translate(-10%, -10%);
  width: 100%;
  height: 100%;
  z-index: 190;
  overflow: hidden;
  background: #70aed2;
  opacity: 0.5;
}
.process-loader2 .span {
  display: table;
  height: 44px;
  left: 50%;
  position: absolute;
  width: 40px;
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.process-loader-compressor {
  position: absolute;
  top: 0%;
  left: 0%;
  //transform: translate(-10%, -10%);
  width: 100%;
  height: 150px;
  z-index: 190;
  overflow: hidden;
  background: #70aed2;
  opacity: 0.5;
}
.process-loader-compressor .span {
  display: table;
  height: 44px;
  left: 50%;
  position: absolute;
  width: 80%;
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.process-loader-error {
  position: absolute;
  top: 20%;
  left: 0%;
  //transform: translate(-10%, -10%);
  width: 100%;
  height: 80%;
  z-index: 190;
  overflow: hidden;
  background: #70aed2;
  opacity: 0.7;
  font-weight: bold !important;
}
.location_indicator {
  margin: 5px auto;
  position: relative;
}

.location_indicator:before,
.location_indicator:after {
  position: absolute;
  content: '';
}

.location_indicator:before {
  width: 20px;
  height: 20px;
  border-radius: 100% 100% 100% 0;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 1);
  -webkit-animation: mapping 1s linear infinite;
  -moz-animation: mapping 1s linear infinite;
  animation: mapping 1s linear infinite;
  -webkit-transform: rotate(-46deg);
  -moz-transform: rotate(-46deg);
  transform: rotate(-46deg);
}

.location_indicator:after {
  width: 30px;
  height: 10px;
  border-radius: 100%;
  left: -4px;
  background-color: rgba(255, 255, 255, 0.2);
  top: 24px;
  z-index: -1;
}

@-webkit-keyframes mapping {
  0% {
    top: 0;
  }
  50% {
    top: -5px;
  }
  100% {
    top: 0;
  }
}
@-moz-keyframes mapping {
  0% {
    top: 0;
  }
  50% {
    top: -5px;
  }
  100% {
    top: 0;
  }
}
@-moz-keyframes mapping {
  0% {
    top: 0;
  }
  50% {
    top: -5px;
  }
  100% {
    top: 0;
  }
}

/*search style*/
.link-search {
  display: inline-block;
  transition: all 0.5s !important;
  //transition: none !important;
  //background-color: transparent !important;
  //border: none;
  color: #fff;
  //padding: 5px;
  //transform: translateY(-3px) !important;
}
.link-search:hover {
  transform: translateY(2px) !important;
  //transition: none !important;
  //background-color: transparent !important;
  color: #fff;
  //border-bottom: 2px solid #fff;
  //text-decoration: none;
}
.btn-search {
  transition: all 1.2s;
  transition: none !important;
  background-color: transparent !important;
  border: none;
  color: #fff;
}
.btn-search:hover {
  transition: none !important;
  background-color: transparent !important;
  color: #fff;
  border-bottom: 2px solid #fff;
}
.btn-search:focus {
  outline: 0;
}
.btn-search-active {
  border-bottom: 2px solid #fff;
  background: #007ad9;
}
.hideSection {
  display: none;
}
.p-button.p-button-text-only .p-button-text {
  padding: 0.429em 0.6em !important;
}
//main search
.p-inputgroup .p-inputgroup-addon {
  background-color: transparent !important;
  color: #fcb815 !important;
}
.p-float-label > label {
  right: 2px !important;
  color: #fcb815 !important;
  text-shadow: 0.5px 0.5px 0px rgba(0, 0, 0, 0.9) !important;
  font-size: 0.9rem;
  //font-weight: 200 !important;
  // line-height: 1.2;
}

.search-province {
  width: 133px;
  @media (max-width: 575px) {
    width: 150px;
  }
  @media (max-width: 320px) {
    width: 200px;
  }
}
.search-area {
  width: 133px;
  @media (max-width: 575px) {
    width: 150px;
  }
  @media (max-width: 320px) {
    width: 200px;
  }
}
.search-type {
  width: 133px;
  @media (max-width: 575px) {
    width: 150px;
  }
  @media (max-width: 320px) {
    width: 200px;
  }
}
.search-business-offer {
  width: 133px;
  @media (max-width: 575px) {
    width: 150px;
  }
  @media (max-width: 320px) {
    width: 200px;
  }
}
.search-min-price {
  width: 133px;
  @media (max-width: 575px) {
    width: 150px;
  }
  @media (max-width: 320px) {
    width: 200px;
  }
}
.search-max-price {
  width: 133px;
  @media (max-width: 575px) {
    width: 150px;
  }
  @media (max-width: 320px) {
    width: 200px;
  }
}
.search-min-area {
  width: 133px;
  @media (max-width: 575px) {
    width: 150px;
  }
  @media (max-width: 320px) {
    width: 200px;
  }
}
.search-max-area {
  width: 133px;
  @media (max-width: 575px) {
    width: 150px;
  }
  @media (max-width: 320px) {
    width: 200px;
  }
}

.search-price-label {
  width: 56px !important;
  // @media (max-width: 575px) {
  //   width: 120px;
  // }
  // @media (max-width: 320px) {
  //   width: 120px;
  // }
}
.search-area-label {
  width: 71px !important;
  // @media (max-width: 575px) {
  //   width: 120px;
  // }
  // @media (max-width: 320px) {
  //   width: 120px;
  // }
}

.search-page-order-style {
  max-width: fit-content;
  //width: intrinsic; /* Safari/WebKit uses a non-standard name */
  max-width: -moz-max-content; /* Firefox/Gecko */
  max-width: -webkit-max-content; /* Chrome */
  width: 200px;
  color: #581f18;
}
.search-page-province {
  width: 133px;
  @media (max-width: 768px) {
    width: 150px;
  }
  @media (max-width: 360px) {
    width: 200px;
  }
}
.search-page-area {
  width: 133px;
  @media (max-width: 768px) {
    width: 150px;
  }
  @media (max-width: 360px) {
    width: 200px;
  }
}
.search-page-type {
  width: 133px;
  @media (max-width: 768px) {
    width: 150px;
  }
  @media (max-width: 360px) {
    width: 200px;
  }
}
.search-page-business-offer {
  width: 133px;
  @media (max-width: 768px) {
    width: 150px;
  }
  @media (max-width: 360px) {
    width: 200px;
  }
}
.search-page-min-price {
  width: 133px;
  @media (max-width: 768px) {
    width: 150px;
  }
  @media (max-width: 360px) {
    width: 200px;
  }
}
.search-page-max-price {
  width: 133px;
  @media (max-width: 768px) {
    width: 150px;
  }
  @media (max-width: 360px) {
    width: 200px;
  }
}
.search-page-min-area {
  width: 133px;
  @media (max-width: 768px) {
    width: 150px;
  }
  @media (max-width: 360px) {
    width: 200px;
  }
}
.search-page-max-area {
  width: 133px;
  @media (max-width: 768px) {
    width: 150px;
  }
  @media (max-width: 360px) {
    width: 200px;
  }
}
.search-page-bedroom {
  width: 133px;
  @media (max-width: 768px) {
    width: 150px;
  }
  @media (max-width: 360px) {
    width: 200px;
  }
}
.search-page-bathroom {
  width: 133px;
  @media (max-width: 768px) {
    width: 150px;
  }
  @media (max-width: 360px) {
    width: 200px;
  }
}
.search-page-floor {
  width: 133px;
  @media (max-width: 768px) {
    width: 150px;
  }
  @media (max-width: 360px) {
    width: 200px;
  }
}
.search-page-view-direction {
  width: 133px;
  @media (max-width: 768px) {
    width: 150px;
  }
  @media (max-width: 360px) {
    width: 200px;
  }
}
.search-page-random {
  width: 265px;
  @media (max-width: 768px) {
    width: 300px;
  }
  @media (max-width: 360px) {
    width: 200px;
  }
  @media (max-width: 360px) {
    width: 200px;
  }
}
.search-page-filters {
  width: 265px !important;
  @media (max-width: 768px) {
    width: 300px !important;
  }
  @media (max-width: 360px) {
    width: 200px !important;
  }
}
.search-page-office {
  width: 200px;
  @media (max-width: 768px) {
    width: 142px;
  }
  @media (max-width: 360px) {
    width: 200px;
  }
}
.search-min-div-container {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  width: 558px;
  height: 170px;
  //background-color: #FCB815; //#17a2b8;
  opacity: 1;
  border-radius: 5px;
  transition: all 0.6s;
  @media (max-width: 1200px) {
    top: 200px;
    transform: translateX(-50%);
  }
  @media (max-width: 992px) {
    top: 150px;
    transform: translateX(-50%);
  }
  @media (max-width: 768px) {
    top: 75px;
    transform: translateX(-50%);
  }
  // heretop
  @media (max-width: 575px) {
    top: 65px;
    transform: translate(-50%, 1%);
    //display: none;
    width: 320px;
    height: 212px;
  }
  @media (max-width: 320px) {
    top: 60px;
    transform: translate(-50%, 1%);
    //display: none;
    width: 215px;
    height: 275px;
  }
  // @media (max-width: 330px) {
  //   display: none;
  // }
  background-color: #333333;
  opacity: 0.3;
}

.search-min-div-container-expanded {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  width: 558px;
  height: 170px;
  //background-color: #FCB815; //#17a2b8;
  opacity: 1;
  border-radius: 5px;
  transition: all 0.6s;
  @media (max-width: 1200px) {
    top: 200px;
    transform: translateX(-50%);
  }
  @media (max-width: 992px) {
    top: 150px;
    transform: translateX(-50%);
  }
  @media (max-width: 768px) {
    top: 75px;
    transform: translateX(-50%);
  }
  // heretop
  @media (max-width: 575px) {
    top: 65px;
    transform: translate(-50%, 1%);
    //display: none;
    width: 320px;
    height: 264px;
  }
  @media (max-width: 320px) {
    top: 60px;
    transform: translate(-50%, 1%);
    //display: none;
    width: 215px;
    height: 515px;
  }
  // @media (max-width: 330px) {
  //   display: none;
  // }
  background-color: #333333;
  opacity: 0.3;
}
.search_min_div {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  width: 558px;
  height: 127px;
  background: transparent;
  transition: all 0.6s;
  //border: 2px solid #f8c012;
  //border: 2px solid rgb(245, 245, 194);
  border-radius: 5px;
  direction: rtl;
  @media (max-width: 1200px) {
    top: 200px;
    transform: translateX(-50%);
  }
  @media (max-width: 992px) {
    top: 150px;
    transform: translateX(-50%);
  }
  @media (max-width: 768px) {
    top: 75px;
    transform: translateX(-50%);
  }
  // heretop
  @media (max-width: 575px) {
    top: 65px;
    transform: translate(-50%, 1%);
    //display: none;
    width: 320px;
    height: 245px;
  }
  @media (max-width: 320px) {
    top: 60px;
    transform: translate(-50%, 1%);
    //display: none;
    width: 215px;
    height: 490px;
  }
  // @media (max-width: 330px) {
  //   display: none;
  // }
}
.search_div {
  // border: 2px solid #f8c012;
  // border-radius: 5px;
  direction: rtl;
  background-color: #efefed; // rgb(208, 230, 252);
  // @media (max-width: 1200px) {
  // }
  // @media (max-width: 992px) {
  // }
  // @media (max-width: 768px) {
  // }
  // @media (max-width: 576px) {
  // }
  padding-top: 10px;
  padding-right: 5px;
  .btn {
    width: 120px;
    @media (max-width: 1200px) {
      width: 120px;
    }
    @media (max-width: 992px) {
      width: auto;
    }
    @media (max-width: 768px) {
      width: 120px;
    }
    @media (max-width: 576px) {
      width: 120px;
    }
  }
}
// heretop
// .btn_full_search {
//   position: absolute;
//   top: 50px;
//   left: 50%;
//   transform: translateX(-50%);
//   background-color: #FCB815;
//   border-radius: 20px;
//   width: 150px;
//   transition: all 1.2s;
//   // @media (min-width: 577px) {
//   //   display: none;
//   // }
//   @media (min-width: 330px) {
//     display: none;
//   }
// }
// .btn_full_search:hover {
//   background-color: #ecd497;
//   .btn_full_search_icon {
//     transform: translateX(8px);
//   }
// }
// .btn_full_search_icon {
//   display: inline-block;
//   transition: all 1.2s;
// }
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.p-multiselect {
  direction: ltr;
}
.filter_multiselected-items {
  background: #007ad9;
  color: #fff;
  padding: 2px 4px;
  margin: 0 0.286em 0 0;
  display: inline-block;
  vertical-align: middle;
  height: 1.857em;
  border-radius: 3px;
}
.p-inputgroup .p-inputgroup-addon {
  padding: 0px !important;
  padding-top: 0.429em !important;
  min-width: 0px !important;
}
// .view_direction_style {
//   width: 180px;
//   @media (max-width: 992px) {
//     width: 180px;
//   }
//   @media (max-width: 768px) {
//     width: auto;
//   }
// }

.label_narrow {
  margin-bottom: 0px !important;
}
.login_label_narrow {
  margin-bottom: 0px !important;
  display: inline-block;
  width: auto;
  @media (max-width: 768px) {
    width: 218px;
  }
}
/*dashboard*/
/* navbar */
.dashboard-sidebar {
  height: auto;

  padding-left: 10px;
  padding-right: 10px;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d2bd88+0,4e4a40+100 */
  background: rgb(210, 189, 136); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(210, 189, 136, 1) 0%,
    rgba(78, 74, 64, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(210, 189, 136, 1) 0%,
    rgba(78, 74, 64, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(210, 189, 136, 1) 0%,
    rgba(78, 74, 64, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d2bd88', endColorstr='#4e4a40',GradientType=0 ); /* IE6-9 */

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 5px 7px 25px rgb(150, 150, 150);
}
.p-component {
  font-size: 16px !important;
  font-family: Arial, Helvetica, sans-serif !important;
}

.update-agency .agency-dashboard-sidebar {
  height: 600px;
  padding-left: 10px;
  padding-right: 10px;

  background: rgb(167, 207, 223); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(167, 207, 223, 1) 0%,
    rgba(35, 83, 138, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(167, 207, 223, 1) 0%,
    rgba(35, 83, 138, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(167, 207, 223, 1) 0%,
    rgba(35, 83, 138, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a7cfdf', endColorstr='#23538a',GradientType=0 ); /* IE6-9 */

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 5px 7px 25px rgb(150, 150, 150);
}

.bottom-border {
  border-bottom: 1px groove #eee;
}

.dashboard-sidebar-link {
  transition: all 0.4s;
}

.dashboard-sidebar-link:hover {
  background-color: #dc3545;
  border-radius: 5px;
}

.dashboard-active {
  background-color: #f8c012;
  border-radius: 7px;
  box-shadow: 2px 5px 10px #111;
  transition: all 0.3s;
}

//property
.round-div {
  border: 1px solid #dbdee0;
  border-radius: 10px;
}

.hhhh {
  background-color: transparent;
  border: 0;
  font-size: 1em;
}

//////add property
body .builtAtCalendar .p-datepicker:not(.p-datepicker-inline) {
  width: 200px;
}
body .built-at-calendar .p-datepicker:not(.p-datepicker-inline) {
  width: 200px;
}
body .reconstructed-at-calendar .p-datepicker:not(.p-datepicker-inline) {
  width: 200px;
}
body .ReconstructedAtCalendar .p-datepicker:not(.p-datepicker-inline) {
  width: 200px;
}

.current-photos_inside_container {
  position: relative;
  height: 168px;
  width: 100%;
  margin: 2px;
  background-color: #f5e2b7;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  overflow: hidden;
}
.current-photos_inside_container:hover {
  overflow: visible !important;
  z-index: 200;
}
.fileupload_btn_deleted {
  border-radius: 50%;
  margin: 1px;
  padding: 1px 5px;
}
.fileupload_image-container {
  position: relative;
  display: inline-block;
  // margin: 0px;
  // margin-right: 5px;
  // margin-bottom: 5px;
  // //float: left;
  // width: 23%;
  height: 172px;
  contain: strict;
  padding: 2px;
  //margin-bottom: 2px;
  //box-sizing: border-box;
  @media (max-width: 1200px) {
    // width: 31%;
  }
}
.fileupload_image_inside_container {
  position: relative;
  height: 185px;
  width: 100%;
  margin: 2px;
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  overflow: hidden;
}
.fileupload_image_inside_container:hover {
  overflow: visible !important;
  z-index: 200;
}
.fileupload_div_delete_image {
  position: absolute;
  left: 20%;
  top: 2%;
  transform: translate(-20%, -2%);
  width: 20%;
  height: 20%;
  z-index: 100;
}
.fileupload_img {
  height: 130px;
  // width: 78px;
}
.fileupload_property_img {
  width: 78px;
}
//userprofile
//login style
.simple-div input.form-control {
  background: transparent;
  border-color: transparent;
  border-bottom: 2px solid #ccc;
  border-radius: 0;
  transition: all 0.8s;
  pointer-events: none;
}
.simple-div input.form-control:focus {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
  color: #000000;
  border-bottom: 2px solid #f5593d;
}

// .simple-div .submit-button {
//   background: #f5593d;
//   color: #eee;
//   transition: all 0.4s;
// }
// .simple-div .submit-button:hover {
//   background: #f9793d;
//   transform: translateY(-3px);
// }

.p-datatable .p-datatable-header {
  color: #17a2b8 !important;
  font-size: 2.5rem !important;
  font-weight: 300 !important;
  line-height: 1.2 !important;
  padding: 0px !important;
  padding-bottom: 4px !important;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  //   'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  //   'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.table-functions .p-button .p-button-text {
  display: none !important;
}
.table-functions .p-button {
  padding: 5px 10px !important;
}

.p-tooltip.p-component.p-tooltip-bottom {
  z-index: 200 !important;
}
body .p-datatable .p-datatable-tbody > tr > td {
  text-align: center;
  padding: 0.2em 0.1em !important;
}

body .p-datatable .p-datatable-tbody > tr > td button {
  margin: 2px !important;
}
.display_4 {
  //color: #17a2b8 !important;
  color: #fcb815;
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display_5 {
  //color: #e0a800 !important; //warning
  color: #fcb815;
  //color: #218838 !important; //success
  //color: #c82333 !important; //danger
  //color: #138496 !important; //info
  //color: #007bff !important; //primary
  font-size: 1.5rem;
  font-weight: 200;
  line-height: 1.2;
}
.display_faq_question {
  //color: #e0a800 !important; //warning
  color: #000;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 150;
  line-height: 1.2;
}
.display_faq {
  //color: #e0a800 !important; //warning
  color: #fcb815;
  //color: #218838 !important; //success
  //color: #c82333 !important; //danger
  //color: #138496 !important; //info
  //color: #007bff !important; //primary
  font-size: 1.5rem;
  font-weight: 350;
  line-height: 1.2;
}
.sub-title {
  color: #581f18;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.1;
}
.display_6 {
  //color: #e0a800 !important; //warning
  color: #581f18;
  //color: #218838 !important; //success
  //color: #c82333 !important; //danger
  //color: #138496 !important; //info
  //color: #007bff !important; //primary
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.2;
}
.display_gold {
  //color: #e0a800 !important; //warning
  color: #fcb817 !important;
  //color: #218838 !important; //success
  //color: #c82333 !important; //danger
  //color: #138496 !important; //info
  //color: #007bff !important; //primary
  // font-size: 1.4rem;
  // font-weight: 300;
  // line-height: 1.2;
}
.show-title {
  color: #000;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.2;
  @media (max-width: 576px) {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.2;
  }
}
.display_title {
  color: #581f18;
  //color: #218838 !important; //success
  //color: #c82333 !important; //danger
  //color: #138496 !important; //info
  //color: #007bff !important; //primary
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.2;
  @media (max-width: 576px) {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.2;
  }
}
.display_7 {
  //color: #e0a800 !important; //warning
  color: #581f18;
  //color: #218838 !important; //success
  //color: #c82333 !important; //danger
  //color: #138496 !important; //info
  //color: #007bff !important; //primary
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.2;
}

.display_7_bold {
  //color: #e0a800 !important; //warning
  color: #fcb817;
  //color: #218838 !important; //success
  //color: #c82333 !important; //danger
  //color: #138496 !important; //info
  //color: #007bff !important; //primary
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.2;
}
.display_mandatory {
  font-size: 0.9rem !important;
  text-shadow: 1.5px 1.5px 1px #fff;

  color: #581f18;
  font-weight: 300;
  line-height: 1.2;
}
.display_8 {
  // color: #581f18 !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 5px;
  text-shadow: 1.5px 1.5px 1px #fff;
}
.display_5_bold {
  //color: #e0a800 !important; //warning
  color: #fcb815;
  //color: #218838 !important; //success
  //color: #c82333 !important; //danger
  //color: #138496 !important; //info
  //color: #007bff !important; //primary
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
}
.display_2_bold {
  //color: #e0a800 !important; //warning
  color: #fcb815;
  //color: #218838 !important; //success
  //color: #c82333 !important; //danger
  //color: #138496 !important; //info
  //color: #007bff !important; //primary
  font-size: 1.05rem;
}
.btn_process {
  border-radius: 50% !important;
  margin: 0px 5px !important;
}
.over_screen {
  z-index: 1;
}
.table_container {
  direction: rtl;
  //height: 670px;
  @media (min-width: 768px) {
    direction: rtl;
  }
}
.table_td {
  word-wrap: break-word;
  padding: 4px 1px !important;
}
.p-datatable-responsive .p-datatable-tbody > tr.p-datatable-row > td {
  @media (max-width: 768px) {
    text-align: right !important;
  }
}
.table-functions .p-column-title {
  display: none !important;
}
.table-functions {
  @media (max-width: 786px) and (min-width: 640px) {
    word-wrap: break-word !important;
    width: auto !important;
  }
}

.show-property-whatsapp {
  background: #fcb815; //#f18805;
  transition: all 0.4s;
  border-radius: 2px !important;
  color: #000;
  padding: 0px 5px;
}
.show-property-whatsapp:hover {
  background: #f18805;
  transform: translateY(-2px);
}

.show-property-link {
  transition: all 0.4s;
  border-radius: 2px !important;
  color: #fcb815 !important;
}
.show-property-link:hover {
  transform: translateY(-2px);
  color: #f18805 !important;
}
.show-property-table {
  direction: rtl !important;
  border-radius: 5px !important;
  // border: 1px solid rgba(0, 0, 0, 0.125);
  text-align: right;
  overflow-x: auto !important;
  width: 95% !important;
  // border-collapse: initial !important;
  // table-layout: fixed !important;
  // display: inline-table !important;
}
.show-property-table-contact {
  direction: rtl !important;
  border-radius: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  text-align: center;
  overflow-x: auto !important;
  width: 100% !important;
  border-collapse: initial !important;
  // table-layout: fixed !important;
  // display: inline-table !important;
}
.tableView {
  direction: rtl !important;
  border-radius: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  text-align: right;
  overflow-x: auto !important;
  width: 100% !important;
  margin-top: 1rem;
  border-collapse: initial !important;
  // table-layout: fixed !important;
  // display: inline-table !important;
}
.padding-table {
  margin-right: -1rem;
}
.padding-telegram {
  margin-right: -2.3rem;
}
.show-property-td {
  padding: 0.75rem 0.5rem;
  word-wrap: break-word;
  //padding-right: 20px;
  //width: 120px !important;
}
.show-property-td1 {
  padding: 0.05rem 0.005rem;
  word-wrap: break-word;
  //padding-right: 20px;
  //width: 120px !important;
}
.show-property-td3 {
  padding: 0.05rem 0.5rem;
  word-wrap: break-word;
  //padding-right: 20px;
  //width: 120px !important;
}
table {
  border-collapse: initial;
}
.show-property-tr {
  border-radius: 5px !important;
  // background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  // width: 120px !important;
  word-break: break-word;
  border-collapse: none !important;
}
.show-property-details {
  background-color: #fff;
}
.show-property-tableview {
  border-radius: 0px !important;
  background-color: #fff;
  // width: 120px !important;
  word-break: break-word;
}
.show_property .carousel .slider-wrapper.axis-horizontal .slider .slide {
  text-align: -webkit-center;
}
.show_property .carousel .slide .legend {
  width: 20% !important;
  bottom: 1% !important;
  cursor: pointer !important;
  transform: translateX(-50%) !important;
  margin-left: 0% !important;
  padding: 5px 0px !important;
  background: #fff !important;
  opacity: 0.6 !important;
  color: #000 !important;
  font-weight: bold !important;
}
.show_property .carousel .slide .legend:hover {
  opacity: 0.9 !important;
}

.img-show-property-thumbnail {
  padding: 0.15rem;
  //margin-left: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  //border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
  width: 200px;
  height: 171px;
  cursor: pointer;
}
.img-show-property-thumbnail-first {
  padding: 0.15rem;
  //margin-left: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  //border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
  width: 350px;
  height: 342px;
  cursor: pointer;
}
.show-property-map {
  padding: 0.15rem;
  //margin-left: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  //border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
  width: 250px;
  height: 342px;
  cursor: pointer;
}
.show-property-horizontal-container {
  direction: rtl;
  display: flex;
  height: 350px;
  overflow-x: auto !important;
  background-color: #f8f9fa;
}
.logopos {
  margin-right: 20px;
  margin-top: 10px;
}
.btn-min-width {
  height: 28px;
  width: 80px;
}
.main_title {
  //color: #17a2b8 !important;
  color: #febb1a !important;
  font-size: 2rem !important;
  font-weight: 300 !important;
  line-height: 1.2 !important;
  @media (max-width: 768px) {
    font-size: 1.5rem !important;
    font-weight: 200 !important;
    line-height: 1.2 !important;
  }
}
.main_title_2 {
  //color: #17a2b8 !important;
  color: #febb1a !important;
  font-size: 2rem !important;
  font-weight: 400 !important;
  line-height: 1.2 !important;
  @media (max-width: 768px) {
    font-size: 1.5rem !important;
    font-weight: 200 !important;
    line-height: 1.2 !important;
  }
}
.main_title_min {
  //color: #17a2b8 !important;
  color: #fff !important;
  font-size: 1.2rem !important;
  font-weight: 300 !important;
  line-height: 1.2 !important;
  @media (max-width: 768px) {
    // color: #581f18 !important;
    font-size: 1.5rem !important;
    font-weight: 200 !important;
    line-height: 1.2 !important;
  }
}
.page_link_sm {
  padding: 0.5rem 1rem !important;
  color: #fcb815 !important;
}
.page_link_sm2 {
  color: #fcb815 !important;
}
.marginr {
  margin-right: -2rem;
}
.page_link_pervious {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
  //color: #FCB815 !important;
}
.page_link_next {
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  //color: #FCB815 !important;
}

///contactForm
.p-datatable-scrollable-body {
  display: -webkit-inline-box;
}
.contact-table .p-datatable table {
  @media (max-width: 640px) {
    width: auto !important;
  }
}
.contact_main_container {
  position: relative;
  direction: rtl;
}
.float-contact-div {
  position: absolute;
  top: 450px;
  left: -5%;
  transform: translateX(10%);
  width: 200px;
  background-color: #fbfcd4 !important;
  border: 1px solid #000;
  border-radius: 10px;
  z-index: 200;
}
.contact-div {
  background-color: #fbfcd4 !important;
  border: 1px solid #000;
  border-radius: 10px;
  z-index: 200;
}
.float-contact-div {
  position: absolute;
  top: 450px;
  left: -5%;
  transform: translateX(10%);
  width: 200px;
  background-color: #fbfcd4 !important;
  border: 1px solid #000;
  border-radius: 10px;
  z-index: 200;
}
.float-contact-div2 {
  position: absolute;
  top: 125px;
  left: -5%;
  transform: translateX(10%);
  width: 200px;
  background-color: #fbfcd4 !important;
  border: 1px solid #000;
  border-radius: 10px;
  z-index: 200;
}
.float-contact-div-EL {
  position: absolute;
  top: 450px;
  left: -10%;
  transform: translateX(10%);
  width: 200px;
  background-color: #fbfcd4 !important;
  border: 1px solid #000;
  border-radius: 10px;
  z-index: 200;
}
.float-contact-div2-EL {
  position: absolute;
  top: 125px;
  left: -10%;
  transform: translateX(10%);
  width: 200px;
  background-color: #fbfcd4 !important;
  border: 1px solid #000;
  border-radius: 10px;
  z-index: 200;
}
.business-offer-div {
  position: absolute;
  top: 60px;
  left: -0px;
  //transform: translate(0px, -500px);
  z-index: 105;
  width: auto;
  padding: 0px 20px;
  height: 25px;
  color: #581f18;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;

  background-color: #581f18;
  color: #fff;
  font-size: 0.85rem;
  font-weight: 400;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9);
  //height: 100%;
  max-height: -webkit-fill-available;
  ////border-top-right-radius: 50%;
  ////border-bottom-right-radius: 50%;
  // display: flex;
  // word-wrap: break-word;
  // align-items: center;
  // justify-content: center;
  -webkit-box-shadow: 2px 0px 3px 0px #fcb815;
  -moz-box-shadow: 2px 0px 3px 0px #fcb815;
  box-shadow: 2px 0px 3px 0px#FCB815;
  text-align-last: center;
  @media (max-width: 767px) {
    top: 104px !important;
  }
}
.business-offer-div-outer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 75px;
  height: 200px;
  overflow: hidden;
}
.business-offer-div-1 {
  position: absolute;
  transform: rotate(-45deg);
  top: 0px;
  left: -25px;
  //transform: translate(0px, -500px);
  z-index: 105;
  width: auto;
  padding: 0px 20px;
  height: 25px;

  color: #581f18;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;

  background-color: #fcb815;
  color: #fff;
  font-size: 0.85rem;
  font-weight: 400;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9);
  //height: 100%;
  max-height: -webkit-fill-available;
  ////border-top-right-radius: 50%;
  ////border-bottom-right-radius: 50%;
  // display: flex;
  // word-wrap: break-word;
  // align-items: center;
  // justify-content: center;
  -webkit-box-shadow: 0px 2px 3px 0px #581f18;
  -moz-box-shadow: 0px 2px 3px 0px #581f18;
  box-shadow: 0px 2px 3px 0px#581f18;

  text-align-last: center;
  overflow: hidden;
}

.business-offer-div-11 {
  position: absolute;
  transform: rotate(-45deg);
  top: 15px;
  left: -73px;
  //transform: translate(0px, -500px);
  z-index: 105;
  width: 200px;
  padding: 0px 20px;
  height: 25px;

  color: #581f18;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;

  background-color: #fcb815;
  color: #fff;
  font-size: 0.85rem;
  font-weight: 400;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9);
  //height: 100%;
  max-height: -webkit-fill-available;
  ////border-top-right-radius: 50%;
  ////border-bottom-right-radius: 50%;
  // display: flex;
  // word-wrap: break-word;
  // align-items: center;
  // justify-content: center;
  -webkit-box-shadow: 0px 1px 3px 0px #581f18;
  -moz-box-shadow: 0px 1px 3px 0px #581f18;
  box-shadow: 0px 1px 3px 0px#581f18;

  text-align-last: center;
}

.business-offer-div2 {
  position: absolute;
  top: 88px;
  left: -0px;
  //transform: translate(0px, -500px);
  z-index: 105;
  width: auto;
  padding: 0px 20px;
  height: 25px;

  color: #581f18;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;

  background-color: #581f18;
  color: #fff;
  font-size: 0.85rem;
  font-weight: 400;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9);
  //height: 100%;
  max-height: -webkit-fill-available;
  ////border-top-right-radius: 50%;
  ////border-bottom-right-radius: 50%;
  // display: flex;
  // word-wrap: break-word;
  // align-items: center;
  // justify-content: center;
  -webkit-box-shadow: 2px 0px 3px 0px #fcb815;
  -moz-box-shadow: 2px 0px 3px 0px #fcb815;
  box-shadow: 2px 0px 3px 0px#FCB815;
  text-align-last: center;
  @media (max-width: 767px) {
    top: 132px !important;
  }
}
//////////////////

// ::-webkit-scrollbar {
//   width: 2px !important;
//   height: 2px !important;
// }
// ::-webkit-scrollbar-button {
//   width: 2px !important;
//   height: 2px !important;
// }

// .horizontal-container {
//   //position: absolute;
//   display: block;
//   //top: 0;
//   //left: 0;
//   width: 80px;
//   max-height: 500px;
//   margin: 0;
//   background: #abc;
//   overflow-y: auto;
//   overflow-x: hidden;
//   transform: rotate(-90deg) translateY(-80px);
//   transform-origin: right top;
// }
// .horizontal-container > div {
//   display: block;
//   padding: 5px;
//   background: #cab;
//   transform: rotate(90deg);
//   transform-origin: right top;
// }

// .squares {
//   padding: 60px 0 0 0;
// }

// .squares > div {
//   width: 60px;
//   height: 60px;
//   margin: 10px;
// }

//////2
.horizontal-container-outer {
  position: relative;
  direction: rtl;
  width: 99%;
  //height: 342px;
  max-height: 575px;
  background-color: #f8f9fa;
  //display: block;
  //width: 10em;
  //overflow: auto;
  //height: 2em;
  //border: 2px solid lightgrey;
  // @media (max-width: 768) {
  //   .horizontal-container-right-button {
  //     visibility: hidden;
  //   }
  //   .horizontal-container-left-button {
  //     visibility: hidden;
  //   }
  // }
  ::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }
  ::-webkit-scrollbar-button {
    width: 0px !important;
    height: 0px !important;
  }
}
.horizontal-container-right-button {
  position: absolute;
  left: 99.5%;
  top: 35%;
  transform: translate(-99.5%, -35%);
  width: 50px;
  visibility: visible;
  height: 40px;
  //background-color: #cc3333;
  box-shadow: 1px 1px 2px 1px #000, -1px -1px 2px 1px #000;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  z-index: 195;
  transition: all 0.6s;
  display: flex;
  word-wrap: break-word;
  align-items: center;
  justify-content: center;
  border: none;
  @media (max-width: 576px) {
    //  visibility: hidden;
  }
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#80000000',GradientType=1 ); /* IE6-9 */
}
.horizontal-container-right-button:hover {
  left: 100%;
  box-shadow: 1px 1px 2px 1px #fcb815, -1px -1px 2px 1px #fcb815;
  border: none !important;
}
.horizontal-container-right-button:focus {
  outline: 0;
}
.horizontal-container-left-button {
  position: absolute;
  left: 0.5%;
  top: 35%;
  transform: translate(-0.5%, -35%);
  width: 50px;
  height: 40px;
  //background-color: #cc3333;
  box-shadow: 1px 1px 2px 1px #000, -1px -1px 2px 1px #000;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 195;
  transition: all 0.6s;
  display: flex;
  word-wrap: break-word;
  align-items: center;
  justify-content: center;
  border: none;
  @media (max-width: 576px) {
    //visibility: hidden;
  }
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#80000000',GradientType=1 ); /* IE6-9 */
}
.horizontal-container-left-button:hover {
  left: 0%;
  box-shadow: 1px 1px 2px 1px #fcb815, -1px -1px 2px 1px #fcb815;
  border: none !important;
}
.horizontal-container-left-button:focus {
  outline: 0;
}

.horizontal-container {
  direction: rtl;
  display: flex;
  height: 330px;
  overflow-x: auto !important;
  background-color: #f8f9fa;
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none;
}
.horizontal-container-all {
  background: transparent !important;
}
.card-item {
  width: 245px;
  flex-shrink: 0;
  height: 300px;
  background-color: #fff;
  //border: 1px solid;
  margin: 5px 10px;
}
.h-c-title {
  background-color: #f9f9f9;
  padding: 10px;
}
.btn-province-active {
  border-color: #581f18;
  background-color: #fcb815;
}
.min-header {
  display: block;
  height: 250px;
  //background-color: #c8c8c8;
  //background-attachment: fixed;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
}
.min-header1 {
  display: block;
  height: 150px;
  //background-color: #c8c8c8;
  //background-attachment: fixed;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
}
input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
} /* FF 4-18 */
input:focus::-moz-placeholder {
  color: transparent;
} /* FF 19+ */
input:focus:-ms-input-placeholder {
  color: transparent;
} /* IE 10+ */
.lakta-label {
  padding-top: 5px;
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  //padding: 0px 5px;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
  margin-bottom: 0px !important;
}
// min header
.main-header {
  position: relative;
  width: 100%;
  height: 450px;
  @media (max-width: 1200px) {
    height: 450px;
  }
  @media (max-width: 992px) {
    height: 400px;
  }
  @media (max-width: 768px) {
    height: 300px;
  }
  @media (max-width: 576px) {
    height: 330px;
  }
  @media (max-width: 320px) {
    height: 382px;
  }
}
.main-header-expanded {
  position: relative;
  width: 100%;
  height: 450px;
  @media (max-width: 1200px) {
    height: 450px;
  }
  @media (max-width: 992px) {
    height: 400px;
  }
  @media (max-width: 768px) {
    height: 300px;
  }
  @media (max-width: 576px) {
    height: 380px;
  }
  @media (max-width: 320px) {
    height: 625px;
  }
}
.business-offer-dropdown {
  min-width: max-content !important;
}
body .business-offer-dropdown-div .p-inputtext {
  font-size: 14px;
  color: #fff !important;
  background: transparent !important;

  // border: none !important;
  // box-shadow: none !important;
}

body .business-offer-dropdown-div .p-inputtext:hover {
  // border: none !important;
  // box-shadow: none !important;
}
body .business-offer-dropdown-div .p-dropdown .p-dropdown-trigger {
  color: #fff !important;
  background: transparent !important;
  // border: none !important;
  // box-shadow: none !important;
}
// .business-offer-dropdown-div .p-inputtext {
//   background-color: transparent !important;
// }
// .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
//   left: -50% !important;
// }
// .p-dropdown .p-dropdown-label {
//   left: 50% !important;
// }
// .p-dropdown .p-dropdown-label {
//   padding-left: 2em !important;
//   padding-right: 0.1px !important;
//   width: 4e;
// }
.p-dropdown .p-dropdown-label {
  padding-left: 1.5em !important;
  padding-right: 0.2em !important;
  width: 4.5em !important;
  // border: none !important;
  // box-shadow: none !important;
}

.p-dropdown .p-dropdown-trigger {
  right: 2.7em !important;
  top: 0.4em !important;
  // border: none !important;
  // box-shadow: none !important;
  font-size: 0.7rem !important;
}
body .business-offer-dropdown-div .p-dropdown {
  border: none !important;
  box-shadow: none !important;

  color: #fff !important;
  //font-size: 1rem !important;
  //font-weight: 400 !important;
  //padding: 0px 5px;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9) !important;
}

body .business-offer-dropdown-div .p-dropdown:hover {
  border: none !important;
  box-shadow: none !important;
}
.search-form-body-toggle {
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.search-form-body-toggle2 {
  -webkit-animation: fadeIn2 1s;
  animation: fadeIn2 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.rounddiv {
  margin-right: 1px;
}
.tooltipicon:hover {
  color: #f18805;
  cursor: pointer;
  transition: all 0.9s;
}
.tooltipicon1:hover {
  color: #f18805;
  cursor: pointer;
}
.tooltipicon2:hover {
  color: #f18805;
  cursor: pointer;
}
.tooltipicon_message:hover {
  color: #f18805;
  cursor: pointer;
}
.tooltipicon_message1:hover {
  color: #f18805;
  cursor: pointer;
}
.tooltipicon .tooltiptext {
  visibility: hidden;
  width: 120px;
  height: 35px;
  background-color: rgb(41, 39, 39);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  margin-top: -38px;
  margin-right: -60px;
}
.tooltipicon1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  height: 35px;
  background-color: rgb(41, 39, 39);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  margin-top: -38px;
  margin-right: -90px;
}
.tooltipicon2 .tooltiptext {
  visibility: hidden;
  width: 120px;
  height: 35px;
  background-color: rgb(41, 39, 39);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  margin-top: -38px;
  margin-right: -50px;
}
.tooltipicon_message .tooltiptext_message {
  visibility: hidden;
  width: 80px;
  height: 35px;
  background-color: rgb(41, 39, 39);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  margin-top: 2px;
  margin-right: -122px;
}
.tooltipicon_message1 .tooltiptext_message1 {
  visibility: hidden;
  width: 80px;
  height: 35px;
  background-color: rgb(41, 39, 39);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  margin-top: 2px;
  margin-right: 1px;
}
.tooltipicon:hover .tooltiptext {
  visibility: visible;
}
.tooltipicon1:hover .tooltiptext {
  visibility: visible;
}
.tooltipicon2:hover .tooltiptext {
  visibility: visible;
}
.tooltipicon_message:hover .tooltiptext_message {
  visibility: visible;
}
.tooltipicon_message1:hover .tooltiptext_message1 {
  visibility: visible;
}
.leaflet-top,
.leaflet-bottom {
  z-index: 190 !important;
}
.leaflet-pane {
  z-index: 180 !important;
}
.faq-text-shadow {
  text-shadow: 'fcb814';
}
.faq-margin {
  margin-right: 0.2rem;
}
.margin-q {
  margin-right: 6rem;
}
.margin-q1 {
  margin-right: 1rem;
}
.p-multiselect-header:after {
  content: 'تحديد الكل';
}
// .p-dialog-titlebar{
//   text-align-last: right;
// }
// @-webkit-keyframes search-toggle {
//   0% {
//     // transform: translateX(-100px);
//     // opacity: 0;
//     //.search-form-body {
//     visibility: hidden !important;
//     background-color: #581f18 !important;
//     //}
//   }
//   100% {
//     // transform: translate(0);
//     // opacity: 1;
//     //.search-form-body {
//     visibility: visible !important;
//     background-color: #70aed2 !important;
//     //}
//   }
// }
.categories-title-div{
  margin-top: 10px;
  padding: 5px 0px;
  background-color: #EFEFED;
  border: 1px solid #DADADA;
  border-bottom: 0px;
  direction: rtl;
}
.categories-div{
  padding: 5px 0px;
  background-color: #EFEFED;
  border: 1px solid #DADADA;
  border-top: 0px;
  direction: rtl;
}

.category-item{
  color: #581f18 !important;
}

.ikar-breadcrumbs{
  list-style-type: none;
  white-space: "nowarp";
  margin-bottom: 0px !important;
}

.ikar-breadcrumbs li{
  display: inline;
}
